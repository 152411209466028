<template>
  <main>
    <trac-loading v-if="isLoading" />
    <div v-if="emailSent" class="flex w-full justify-center mt-16 items-center">
      <div class="mt-6 max-w-4xl auth-shadow p-5 flex-col pb-16 rounded-md w-full flex items-center justify-center">
        <div class="flex justify-center">
          <img width="4" class="w-2/5" src="./../assets/images/sucess-mark.png" alt="">
        </div>

        <h3 class="text-xl font-semibold text-center">Password reset link has been sent to your email.</h3>
        <p class="text-sm mt-2 text-center text-gray-600 my-2">We sent you a password reset link to your email <br> <span class="font-semibold text-primaryBlue">{{ email }}</span></p>
        <p class="text-sm mt-2 text-center text-gray-600 my-2">
          <trac-button @button-clicked="$router.replace('/login')">Continue</trac-button>
        </p>

        <div class="">
          <hr class="my-10 w-full">
          <p class="font-semibold text-sm">Didn’t get the email?</p>
          <p class="text-xs mt-2">If you don’t receive an email within 5 mins, it could be one of the following issues:</p>
  
          <ol class="list-decimal text-xs mt-2 pl-8">
            <li class="py-1">The email could be in your spam or promotions folder</li>
            <li class="py-1">
              You accidentally entered another email address that exist on our database
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div v-else class="flex w-full justify-center mt-16 items-center">
      <div ref="plac"
        class="max-w-4xl auth-shadow p-5 flex-col pb-16 rounded-md w-full flex items-center justify-center">
        <div class="flex items-center justify-center flex-col mt-12 mb-6">
          <img src="@/assets/images/traction-logo.png" class="w-48" alt="" />
        </div>
        <trac-validation-observer slim v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(sendEmail)" class="max-w-xl ma w-full mt-12">
            <h1 class="text-lg font-medium text-accentDark">Forgot Password</h1>
            <trac-validation-provider name="email" rules="required|email" v-slot="{ errors }">
              <trac-input data-test="forgot-password-field" v-model.trim="email" placeholder="Email Address" class="mt-5" ref="emails" type="email"
                tag="div" />
              <trac-input-error v-if="errors[0]">{{
              errors[0]
              }}</trac-input-error>
            </trac-validation-provider>

            <div class="mt-8 flex items-center justify-center flex-col">
              <trac-button data-test="send-email" buttonType="submit" class="w-full max-w-xs">
                <div class="py-1">Send Email</div>
              </trac-button>
            </div>
          </form>
        </trac-validation-observer>
      </div>
    </div>
  </main>
</template>

<script>
import { eventBus } from "./../eventBus";

export default {
  data() {
    return {
      isLoading: false,
      emailSent: false,
      email: "",
    };
  },
  mounted() { },
  methods: {
    async sendEmail() {
      this.isLoading = true;

      const payload = {
        email: this.email,
      };

      const res = await this.$store.dispatch("RESET_PASSWORD", payload);
      // = this.$store.getters["FORGOT_PASSWORD_RES"];
      
      this.emailSent = res.status;

      if (res.status) {
        // localStorage.clear();
        sessionStorage.clear();
        this.$intercom.shutdown(); // Shutdown intercom
        this.$MixpanelReset(); // Reset Mixpanel
        this.clearAllPeriodicSyncInstructions()
        // this.$router.replace('/login');
        this.isLoading = false
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
      }

      this.isLoading = false;
    },
  },
};
</script>

<style>

</style>
